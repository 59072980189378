import React, {Component} from 'react';
import ReactMarkdown from "react-markdown";
import axios from "axios";

import PingPong from "../components/PingPong";

import config from "../config";

function Collapsable({title, children}){
    const [expanded, setExpanded] = React.useState(false);

    const toggle = React.useCallback((e) => {
        e.preventDefault();
        setExpanded(!expanded);
    }, [expanded]);

    return (
        <div className="collapsable-wrapper">
            <div className="collapsable-title">
                {title}
                <button onClick={toggle}>More...</button>
            </div>
            {expanded && (
                <div className="collapsable-content">{children}</div>
            )}
        </div>
    );
}

function Skill({
                   name,
                   experience,
                   firstActivelyUsed,
                   lastActivelyUsed,
                   level,
               }) {
    const currentYear = (new Date()).getFullYear();
    const yearOfUse = (lastActivelyUsed ? (new Date(lastActivelyUsed)).getFullYear() : 'now');
    const currentLevel = Math.max(0, level - (currentYear - yearOfUse));
    const yearsOfExperience = (lastActivelyUsed ? yearOfUse : currentYear) - new Date(firstActivelyUsed).getFullYear();
    return (
        <li>
            <Collapsable
                title={<>{name}: {level} {lastActivelyUsed && (<span>({currentLevel})</span>)}</>}
            >
                <div>
                    top level: {level}
                </div>
                <div>
                    years of experience: {yearsOfExperience}
                </div>
                <div>
                    Last worked: {yearOfUse}
                </div>
                {lastActivelyUsed && (
                    <div>
                        level considering degradation: {currentLevel}
                    </div>
                )}
            </Collapsable>
        </li>
    )
}

function WorkExperience({
                            description,
                            achievements,
                            company,
                            location,
                            workStart,
                            workEnd,
                            position,
                        }){
    const workStartYear = (new Date(workStart)).getFullYear();
    const workStartEnd = workEnd ? (new Date(workEnd)).getFullYear() : 'now';
    return(
        <li>
            <Collapsable
                title={(
                    <>
                        {position} | {company} | {location}
                        <br/>
                        {workStartYear} - {workStartEnd}
                    </>
                )}
            >
                <div><ReactMarkdown source={description} /></div>
                <div><ReactMarkdown source={achievements} /></div>
            </Collapsable>
        </li>
    );
}

class Home extends Component {
    imgRef = React.createRef();

    state = {
        error: null,
        skillsCategories: [],
        workExperiences: [],
        topContent: null,
        bottomContent: null,
        pingPong: false
    }
    componentDidMount = async () => {
        try {
            const responseSkillsCategories = await axios.get(`${config.apiUrl}/categories`);
            const responseWorkExperiences = await axios.get(`${config.apiUrl}/work-experiences`);
            const responseTopContent = await axios.get(`${config.apiUrl}/home-page-top`);
            const responseBottomContent = await axios.get(`${config.apiUrl}/home-page-bottom`);
            this.setState({
                skillsCategories: responseSkillsCategories.data,
                workExperiences: responseWorkExperiences.data,
                topContent: responseTopContent.data.content,
                bottomContent: responseBottomContent.data.content,
                error: null
            })
        } catch (error) {
            this.setState({error})
        }
    }

    pongPing = () => {
        this.setState({
            pingPong: !this.state.pingPong
        })
    }

    render() {
        const {error, skillsCategories, workExperiences, topContent, bottomContent} = this.state;

        // Print errors if any
        if (error) {
            return <div>An error occurred: {error.message}</div>;
        }

        const yearsOfExperience = (new Date()).getFullYear() - 2012;

        return (
            <div className="content">
                {topContent && (
                    <div>
                        <ReactMarkdown
                            source={topContent.replace('{{yearsOfExperience}}', yearsOfExperience)}
                        />
                    </div>
                )}
                <h2 onClick={this.pongPing}>Skills</h2>
                <div className="skills">{skillsCategories.map(category => {
                    return (
                        <div key={category.id}>
                            <h3>
                                {category.name}
                            </h3>
                            <ul>
                                {category.skills.map(skill => {
                                    return <Skill
                                        {...skill}
                                        key={skill.id}
                                    />
                                })}
                            </ul>
                        </div>
                    )
                })}</div>
                <h2>Experience</h2>
                <ul>{workExperiences.map(workExperience => {
                    return <WorkExperience
                        {...workExperience}
                        key={workExperience.id}
                    />
                })}</ul>
                <div>
                    <ReactMarkdown source={bottomContent} />
                </div>
                {this.state.pingPong && (
                    <PingPong />
                )}
            </div>
        );
    }
}

export default Home;
