import Header from "./components/common/Header";
import Home from "./pages/Home";

import './App.css';

function App() {
  return (
    <div className="App">
        <Header />
      <Home />
    </div>
  );
}

export default App;
