import React, {Component} from 'react';

import styles from './PingPong.module.scss';

const configs = {
    speed: 3
}

function collision(a, b){
    let x = false;
    let y = false;
    let deviation = 0;

    if(a.x1 <= b.x2 && a.x2 >= b.x1){
        x = true;
    }

    if(a.y1 <= b.y2 && a.y2 >= b.y1){
        y = true;
    }

    if(x && y){
        deviation = (a.y - b.y) / 2;
    }

    return {happened: x && y, deviation};
}

const Player = React.forwardRef((props, ref) => {
    return <div ref={ref} className={styles.player} />
})

const Ball = React.forwardRef((props, ref) => {
    return <div ref={ref} className={styles.ball} />
})

class PingPong extends Component {
    speed = configs.speed;
    collide = false;

    pingPongRef = React.createRef();
    playerRef = React.createRef();
    ballRef = React.createRef();
    mouseCoords = {x: 0, y: 0};
    playerCoords = {x1: 0, y1: 0, x2: 0, y2: 0};
    playerForce = {x: 0, y: 0};
    ballCoords = {x1: 0, y1: 0, x2: 0, y2: 0};
    run = true;

    componentDidMount() {
        window.addEventListener('mousemove', this.followMouse);
        const playerBox = this.playerRef.current.getBoundingClientRect();
        const ballBox = this.ballRef.current.getBoundingClientRect();

        this.playerCoords = {
            width: playerBox.width,
            height: playerBox.height,
            x: playerBox.left + playerBox.width / 2,
            y: playerBox.top + playerBox.height / 2,
            x1: playerBox.left,
            y1: playerBox.top,
            x2: playerBox.right,
            y2: playerBox.bottom,
        };
        this.ballCoords = {
            width: ballBox.width,
            height: ballBox.height,
            x: ballBox.left + ballBox.width / 2,
            y: ballBox.top + ballBox.height / 2,
            x1: ballBox.left,
            y1: ballBox.top,
            x2: ballBox.right,
            y2: ballBox.bottom,
        };
        this.tick();
    }

    componentWillUnmount() {
        this.run = false;
        window.removeEventListener('mousemove', this.followMouse)
    }

    followMouse = e => {
        this.playerForce.x = Math.abs(this.mouseCoords.x - e.pageX);
        this.playerForce.y = Math.abs(this.mouseCoords.y - e.pageY);
        this.mouseCoords = {
            x: e.pageX,
            y: e.pageY,
        }

        this.playerCoords = this.calcPlayer(this.mouseCoords.x, this.mouseCoords.y);
        const pingPongRef = this.pingPongRef.current;
        pingPongRef.style.setProperty('--player-x', this.playerCoords.x1 + 'px');
        pingPongRef.style.setProperty('--player-y', this.playerCoords.y1 + 'px');
    }

    calcPlayer(x, y){
        const x1 = x - this.playerCoords.width / 2;
        const y1 = y - this.playerCoords.height / 2;
        return {
            ...this.playerCoords,
            x,
            y,
            x1,
            y1,
            x2: x + this.playerCoords.width,
            y2: y + this.playerCoords.height,
        };
    }

    calcBall(directionX, directionY){
        let ballX = this.ballCoords.x1 + this.speed * directionX;
        let ballY = this.ballCoords.y1 + this.speed * directionY;

        return {
            ...this.ballCoords,
            x: ballX + this.ballCoords.width / 2,
            y: ballY + this.ballCoords.height / 2,
            x1: ballX,
            y1: ballY,
            x2: ballX + this.ballCoords.width,
            y2: ballY + this.ballCoords.height,
        }
    }

    tick(directionX = 1, directionY = 0){
        if(!this.run){
            return;
        }

        // const playerRef = this.playerRef.current;
        // playerRef.style.left = this.playerCoords.x1 + 'px';
        // playerRef.style.top = this.playerCoords.y1 + 'px';

        let ballCoords = this.calcBall(directionX, directionY);
        if(ballCoords.x2 >= window.innerWidth || ballCoords.x1 <= 0){
            directionX = directionX * -1;
        }
        if(ballCoords.y2 >= window.innerHeight || ballCoords.y1 <= 0){
            directionY = directionY * -1;
        }
        ballCoords = this.calcBall(directionX, directionY);

        const {happened: collisionHappened, deviation} = collision(ballCoords, this.playerCoords);
        if (collisionHappened && !this.collide) {
            this.collide = true;
            this.speed = Math.max(this.playerForce.x, configs.speed);
            directionX = directionX * -1;
            directionY = (deviation / 50);
            ballCoords = this.calcBall(directionX * 2, directionY * 2);
        }else{
            this.collide = collisionHappened;
        }

        this.ballCoords = ballCoords;
        this.pingPongRef.current.style.setProperty('--ball-x', this.ballCoords.x1 + 'px');
        this.pingPongRef.current.style.setProperty('--ball-y', this.ballCoords.y1 + 'px');
        // const ballRef = this.ballRef.current;
        // ballRef.style.left = this.ballCoords.x1 + 'px';
        // ballRef.style.top = this.ballCoords.y1 + 'px';

        requestAnimationFrame(() => {
            this.tick(directionX, directionY);
        })
    }

    render() {
        return (
            <div className={styles.pingPong} ref={this.pingPongRef}>
                <Player
                    ref={this.playerRef}
                />
                <Ball ref={this.ballRef} />
            </div>
        );
    }
}

export default PingPong;