import React from 'react';
import ColorThief from 'colorthief';

import me from '../../me.jpg';

const colorThief = new ColorThief();

export default function Header(){
    const imgRef = React.useRef(null);
    const headerImgRef = React.useRef(null);

    const applyPallet = React.useCallback((e) => {
        const img = e.target;
        const canvas = document.createElement('CANVAS');
        const ctx = canvas.getContext('2d');
        let dataURL;
        canvas.height = img.naturalHeight;
        canvas.width = img.naturalWidth;
        ctx.drawImage(img, 0, 0);
        dataURL = canvas.toDataURL();
        requestAnimationFrame(() => {
            headerImgRef.current.style.backgroundImage = `url(${dataURL})`;
            headerImgRef.current.style.opacity = 1;
        })
        const pallet = colorThief.getPalette(img);
        let darkBrightness = Infinity;
        let lightBrightness = -Infinity;
        let dark = [0,0,0];
        let light = [255,255,255];
        pallet.forEach(rgb => {
            const brightness = Math.round(((parseInt(rgb[0]) * 299) +
                (parseInt(rgb[1]) * 587) +
                (parseInt(rgb[2]) * 114)) / 1000)
            if(brightness < darkBrightness && brightness > 50){
                dark = rgb;
                darkBrightness = brightness;
            }
            if(brightness > lightBrightness){
                light = rgb;
                lightBrightness = brightness;
            }
        })
        document.documentElement.style.setProperty('--color-dark', `rgb(${dark.join(',')})`)
        document.documentElement.style.setProperty('--color-light', `rgb(${light.join(',')})`)
    }, [headerImgRef]);

    React.useLayoutEffect(() => {
        imgRef.current.addEventListener('load', applyPallet)

        return () => {
            imgRef.current.removeEventListener('load', applyPallet)
        }
    }, []);

    const imageWidth = Math.round(window.innerWidth * .7);
    const imageHeight = Math.round(window.innerHeight * .7 * .3);

    const placeholderHeight = Math.round(window.innerHeight * .3);

    return (
        <div
            className="header"
            style={{height: `${placeholderHeight}px`}}
        >
            <div className="img" ref={headerImgRef} />
            <img
                src={`https://source.unsplash.com/${imageWidth}x${imageHeight}/?nature`}
                crossOrigin="anonymous"
                alt="top"
                ref={imgRef}
            />
            <div className="me">
                <img src={me} alt="me"/>
            </div>
            <h1>George Tislenko</h1>
        </div>
    )
}
